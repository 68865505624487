import axios from '@/utils/request'
/**
 * 查询客户列表 --所有的客户名称列表
 * @param {ent_id,search_str} sid 唯一标识
 */
const query_flow_info_list = (data) => axios.post('/query_flow_info_list', {...data })
const query_flow_form_info = (data) => axios.post('/query_flow_form_info', {...data })
const query_flow_group_info_list = (data) => axios.post('/query_flow_group_info_list', {...data })
const query_flow_list_have_appr_by_me = (data) => axios.post('/query_flow_list_have_appr_by_me', {...data })

const query_flow_info_all = (data) => axios.post('/query_flow_info_all', {...data })
const query_cust_list_simple = (data) => axios.post('/query_cust_list_simple', {...data })
    /**
     * 用户身份信息：主管理员，子管理员，主管等
     * @param {ent_id,user_id} sid 唯一标识
     */
const query_user_info = (data) => axios.post('/query_user_info', {...data })
    /**
     *获取职位列表
     * @param {ent_id} data
     */
const query_post_info_list = (data) => axios.post('/query_post_info_list', {...data })
    /**
     *判断是否已关注公众号
     * @param {mobile} data
     */
const check_gzh_focus = (data) => axios.post('/check_gzh_focus', {...data })
    /**
     *查询全模块-销售 的人员
     * @param {ent_id} data
     */
const query_all_module_sale_userid = (data) => axios.post('/query_all_module_sale_userid', {...data })
    /**
     *查询全模块-销售 列表
     * @param {ent_id,user_id} data
     */
const query_all_module_sale = (data) => axios.post('//query_all_module_sale', {...data })
    /**
     *设置全模块-销售 权限
     * @param {ent_id, module_id:模块id, type:1部门，人员，2角色, staff_ids, dept_ids, role_ids, read_flag:1只读，2全部} data
     */
const set_all_module_sale = (data) => axios.post('//set_all_module_sale', {...data })
    /**
     *设置表单代写
     * @param {ent_id, user_ids:用户id，逗号分割} data
     */
const update_form_replace_write = (data) => axios.post('/update_form_replace_write', {...data })
    /**
     *查询表单代写的员工列表
     * @param {ent_id} data
     */
const query_form_replace_write_new = (data) => axios.post('/query_form_replace_write_new', {...data })
    /**
     *设置员工账号节点
     * @param {ent_id, user_ids:用户id,逗号分割} data
     */
const update_staff_node = (data) => axios.post('//update_staff_node', {...data })
    /**
     *查询员工账号节点列表
     * @param {ent_id} data
     */
const query_staff_node = (data) => axios.post('//query_staff_node', {...data })
    /**
     *新增用户登陆日志
     * @param {ent_id,mobile} data
     */
const insert_m_login_log = (data) => axios.post('/insert_m_login_log', {...data })
    /**
     *删除消息
     * @param {ent_id,ids} data
     */
const delete_notify = (data) => axios.post('//delete_notify', {...data })
    /**
     *更新消息
     * @param {flow_no:流程号,user_id, action:消息动作类型：1我发起的,2待我审批,3我已审批,4抄送我的,5或签共享}
     */
const update_notify = (data) => axios.post('//update_notify', {...data })
    /**
     *查询消息列表
     * @param { ent_id, user_id, read_flag:非必填，1已读，0未读 } data
     */
const query_notify_list = (data) => axios.post('//query_notify_list', {...data })

/**
 *新增消息
 * @param {ent_id, content:消息内容, type：消息类型,1审批流消息, sender:发送人, target:接收人, action:消息动作类型：1我发起的,2待我审批,3我已审批,4抄送我的,5或签共享, notify_id: 消息id} data
 */
const insert_notify = (data) => axios.post('//insert_notify', {...data })
    /**
     *查询消息条数
     * @param {ent_id,user_id,read_flag:0未读，1已读,action:消息动作类型：1我发起的,2待我审批,3我已审批,4抄送我的,5或签共享} data
     */
const query_notify_num = (data) => axios.post('/query_notify_num', {...data })
    /**
     *查询产品类型下的所有产品list --加售卖规则
     * @param {ent_id,user_id,cat_id:类型id,dept_ids:部门id,逗号分割} data
     */
const query_all_prod_list_of_category_sale_rule = (data) => axios.post('//query_all_prod_list_of_category_sale_rule', {...data })
    /**
     *查询产品类型下的所有产品list  //查询菜品树
     * @param {ent_id,cat_id} data
     */
const query_all_prod_list_of_category = (data) => axios.post('/query_all_prod_list_of_category', {...data })
    /**
     *查询人员的全模块只读权限列表
     * @param {ent_id,user_id} data
     */
const query_all_module_read_power_list = (data) => axios.post('/query_all_module_read_power_list', {...data })
    /**
     *全模块下的权限明细
     * @param {ent_id} data
     */
const query_all_module_read_power_detail = (data) => axios.post('//query_all_module_read_power_detail', {...data })
    /**
     *设置全模块只读权限
     * @param {ent_id,module_id:模块id, type:1部门，人员，2角色, staff_ids:员工id,逗号分割, dept_ids:部门id,逗号分割,role_ids:角色id,逗号分割, read_flag:1只读，2全部 } data
     */
const set_all_module_read_power = (data) => axios.post('//set_all_module_read_power', {...data })
    /**
     *查询人员的目标模块权限列表
     * @param {ent_id,user_id} data
     */
const query_aim_module_power_list = (data) => axios.post('/query_aim_module_power_list', {...data })
    /**
     *查询表单的管理者
     * @param {ent_id,form_id:表单id} data
     */
const query_manager_of_form = (data) => axios.post('//query_manager_of_form', {...data })
    /**
     *查询客户的归档号列表
     * @param {ent_id,cust_id} data
     */
const query_customer_file_list = (data) => axios.post('/query_customer_file_list', {...data })
    /**
     *查询部门的员工列表
     * @param {ent_id,dept_id} data
     */
const query_staff_list_of_dept = (data) => axios.post('//query_staff_list_of_dept', {...data })
    /**
     *查询员工的部门列表（一个员工可能存在于多个部门中）
     * @param {ent_id,staff_id} data
     */
const query_dept_list_of_staff = (data) => axios.post('/query_dept_list_of_staff', {...data })
    /**
     *查询部门及多级子部门下的所有人员列表
     * @param {ent_id,dept_id} data
     */
const query_all_staff_list_of_dept = (data) => axios.post('//query_all_staff_list_of_dept', {...data })
    /**
     *批量修改产品类型的类型
     * @param {ent_id, cat_id:类型id, cat_ids:类型id,逗号分割} data
     */
const update_prod_cat_cat_batch = (data) => axios.post('update_prod_cat_cat_batch', {...data })
    /**
     *批量修改产品的类型
     * @param {ent_id, cat_id:类型id, cat_name:类型名称, prod_ids:产品id,逗号分割} data
     */
const update_prod_cat_batch = (data) => axios.post('//update_prod_cat_batch', {...data })
    /**
     *批量修改产品顺序
     * @param {ent_id,list:[prod_id:产品id,sort:顺序]} data
     */
const update_prod_sort = (data) => axios.post('//update_prod_sort', {...data })
    /**
     *批量修改产品类型顺序
     * @param {data:[ent_id],list:[cat_id:类型id,sort:顺序]} data
     */
const update_category_sort = (data) => axios.post('//update_category_sort', {...data })
    /**
     *批量修改部门顺序
     * @param {data:[ent_id],list:[dept_id,sort]} data
     */
const update_dept_sort = (data) => axios.post('//update_dept_sort', {...data })
    /**
     *查询企业的员工列表---未注册
     * @param {data:[end_id]} data
     */
const query_staff_list_register_not = (data) => axios.post('/query_staff_list_register_not', {...data })
    /**
     *查询企业的员工列表---已注册
     * @param {ent_id} data
     */
const query_staff_list_register_yes = (data) => axios.post('/query_staff_list_register_yes', {...data })
    /**
     *查询企业的员工数及已注册数
     * @param {ent_id} data
     */
const query_ent_staffNum_registerNum = (data) => axios.post('//query_ent_staffNum_registerNum', {...data })
    /**
     *目标模块下的权限明细
     * @param {ent_id,module_id:模块id,read_flag:1只读,2全部} data
     */
const query_aim_module_power_detail = (data) => axios.post('//query_aim_module_power_detail', {...data })
    /**
     *设置目标模块权限
     * @param {ent_id,module_id:模块id,type:1部门，人员，2角色,staff_ids:人员id,逗号分割, dept_ids:部门id,逗号分割, role_ids:角色id,逗号分割,read_flag:1只读，2全部} data
     */
const set_aim_module_power = (data) => axios.post('//set_aim_module_power', {...data })
    /**
     *模块下的权限明细
     * @param {ent_id,module_id} data
     */
const query_module_power_detail = (data) => axios.post('//query_module_power_detail', {...data })
    /**
     *设置模块权限
     * @param {ent_id,module_id:模块id,type:1部门，人员，2角色,staff_ids:人员id,逗号分割,dept_ids:部门id,逗号分割,role_ids:角色id,逗号分割} data
     */
const set_module_power = (data) => axios.post('//set_module_power', {...data })
    /**
     *模块下的人员列表
     * @param {ent_id,module_id:模块id} data
     */
const query_user_list_of_module = (data) => axios.post('//query_user_list_of_module', {...data })
    /**
     *角色下的人员列表
     * @param {ent_id，role_id：角色id} data
     */
    // const query_user_list_of_role = (data) => axios.post('//query_user_list_of_role', { ...data })
    /**
     *设置用户与模块的关系
     * @param {ent_id,read_only:只读标志：1只读，2可读可写, module_id:模块id,staff_ids:员工id,逗号分割} data
     */
const set_user_module_rel = (data) => axios.post('//set_user_module_rel', {...data })
    /**
     *删除角色中的员工
     * @param {ent_id,role_id:角色id, staff_ids:员工id,逗号分割} data
     */
    // const delete_staff_role_rel = (data) => axios.post('//delete_staff_role_rel', { ...data })
    /**
     *设置角色员工的关系
     * @param {ent_id,role_ids:角色id,逗号分割, staff_ids:员工id,逗号分割} data
     */
const set_staff_role_rel = (data) => axios.post('/set_staff_role_rel', {...data })
    /**
     *初始化当月工作日列表
     * @param {} data
     */
const init_work_day_list = (data) => axios.post('//init_work_day_list', {...data })
    /**
     *获取系统日志列表
     * @param {ent_id,user_id,date_begin:开始时间,date_end:结束时间,operate_aim:0基础，1目标，2销售} data
     */
const get_system_logs_list = (data) => axios.post('/get_system_logs_list', {...data })
    /**
     *获取部门列表
     * @param {ent_id,parent_id:父id} data
     */
const get_dept_list = (data) => axios.post('//get_dept_list', {...data })
    /**
     *获取产品列表 --模糊查询
     * @param {ent_id,prod_name:产品名称，模糊查询,active:1有效，0无效} data
     */
const get_prod_list_like = (data) => axios.post('//get_prod_list_like', {...data })
    /**
     *获取产品列表
     * @param {ent_id,parent_id:父id} data
     */
const get_prod_list = (data) => axios.post('//get_prod_list', {...data })
    /**
     *获取员工列表 --模糊查询
     * @param {data:[ent_id:企业id,dept_id:部门id,staff_name:员工名称，模糊查询,active:1有效，0无效]} data
     */
    // const get_staff_list_like = (data) => axios.post('//get_staff_list_like', { ...data })
    /**
     *获取员工列表
     * @param {ent_id,dept_id：部门id,staff_name:员工名称，模糊查询} data
     */
const get_staff_list = (data) => axios.post('//get_staff_list', {...data })
    /**
     *获取时间类型列表
     * @param {data} data
     */
const get_date_type_list = (data) => axios.post('//get_date_type_list', {...data })
    /**
     *获取我的客服信息
     * @param {data} data
     */
const get_cs_info = (data) => axios.post('//get_cs_info', {...data })
    /**
     *获取当前登陆用户的企业信息
     * @param {data:[user_id]} data
     */
    // const get_my_info = (data) => axios.post('//get_my_info', { ...data })
    /**
     *更新用户信息
     * @param {user_id,email,mobile_add:第二电话,sex:性别1男0女,date_birth:生日,area:地区,wechat:微信,dingtalk:钉钉,idcard:身份证号,avatar_data:头像图片,avatar_data_type:头像图片后缀} data
     */
    // const update_user_info = (data) => axios.post('//update_user_info', { ...data })
    /**
     *新增用户建议
     * @param {ent_id,user_id,mobile,adv_type:建议类型,adv_txt:建议文字,adv_data:图片,adv_data_type:图片后缀} data
     */

// 判断是否注册
const register_judge = (data) => axios.post('/register_judge', {...data })
    // 发送验证码
const sms = (data) => axios.post('/sms', {...data })
    // 退出登录
const logout = (data) => axios.post('/logout', {...data })
    // 更换手机号
const change_user_mobile = (data) => axios.post('/change_user_mobile', {...data })
    // 获取基础信息
const get_my_info = (data) => axios.post('/get_my_info', {...data })
    // 我设置
const update_user_info = (data) => axios.post('/update_user_info', {...data })
    // 邮箱认证
const send_check_user_email = (data) => axios.post('/send_check_user_email', {...data })
    //修改密码
const alter_user_pwd = (data) => axios.post('/alter_user_pwd', {...data })
    //获取相关企业信息
const query_user_ent_list = (data) => axios.post('/query_user_ent_list', {...data })
    //我的建议
const add_my_advise = (data) => axios.post('/add_my_advise', {...data })
    // 获取是否有企业
const get_rel_list = (data) => axios.post('/get_rel_list', {...data })
    // 编辑客户  编辑 漏斗
const staff_left_ent = (data) => axios.post('/staff_left_ent', {...data })
    // 删除未认证企业
const delete_un_aut_ent = (data) => axios.post('/delete_un_aut_ent', {...data })
    // 解散认证企业
const dismiss_aut_ent = (data) => axios.post('/dismiss_aut_ent', {...data })
    // 切换企业
const toggle_user_ent = (data) => axios.post('/toggle_user_ent', {...data })
    // 获取用户相关企业列表
const query_user_aut_ent_list = (data) => axios.post('/query_user_aut_ent_list', {...data })
    // 判断该信用代码是否注册过企业
const aut_ent_have_exist = (data) => axios.post('/aut_ent_have_exist', {...data })
    // 添加企业认证的信息
const add_ent_aut_info = (data) => axios.post('/add_ent_aut_info', {...data })
    // 查询用户认证的企业信息-单个
const query_user_aut_ent = (data) => axios.post('/query_user_aut_ent', {...data })
    // 修改用户认证的企业信息-单个
const update_ent_aut_info = (data) => axios.post('/update_ent_aut_info', {...data })
    // 查询子管理员列表
const query_ent_sub_manager_list = (data) => axios.post('/query_ent_sub_manager_list', {...data })
    // 查询子管理员列表
const query_ent_sub_manager_list_by_module = (data) => axios.post('/query_ent_sub_manager_list_by_module', {...data })
    // 查询子管理员
const query_ent_sub_manager = (data) => axios.post('/query_ent_sub_manager', {...data })
    // 获取部门列表
const query_ent_dept_list = (data) => axios.post('/query_ent_dept_list', {...data })
    // 查询结构人员
const query_dept_staff_list = (data) => axios.post('/query_dept_staff_list', {...data })
    // 设置子管理员
const set_ent_sub_manager = (data) => axios.post('/set_ent_sub_manager', {...data })
    // 修改子管理员
const update_ent_sub_manager = (data) => axios.post('/update_ent_sub_manager', {...data })
    // 删除子管理员
const delete_ent_sub_manager = (data) => axios.post('/delete_ent_sub_manager', {...data })
    // 模糊查询
const get_staff_list_like = (data) => axios.post('/get_staff_list_like', {...data })
    // 查询主管理员详情
const query_ent_primary_manager_detail = (data) => axios.post('/query_ent_primary_manager_detail', {...data })
    // 查询角色list
const query_role_info_list = (data) => axios.post('/query_role_info_list', {...data })
    // 删除角色的员工
const delete_staff_role_rel = (data) => axios.post('/delete_staff_role_rel', {...data })
    // 角色下的人员列表
const query_user_list_of_role = (data) => axios.post('/query_user_list_of_role', {...data })
const query_user_list_of_roles = (data) => axios.post('/query_user_list_of_roles', {...data })
    // 删除角色
const delete_role_info = (data) => axios.post('/delete_role_info', {...data })
    // 删除角色组
const delete_role_group_info = (data) => axios.post('/delete_role_group_info', {...data })
    // 修改角色
const update_role_info = (data) => axios.post('/update_role_info', {...data })
    // 修改角色组
const update_role_group_info = (data) => axios.post('/update_role_group_info', {...data })
    // 新增角色
const insert_role_info = (data) => axios.post('/insert_role_info', {...data })
    // 查询角色组list
const query_role_group_info_list = (data) => axios.post('/query_role_group_info_list', {...data })
    // 新增角色组
const insert_role_group_info = (data) => axios.post('/insert_role_group_info', {...data })
    // 查询导入日志
const query_import_log_list = (data) => axios.post('/query_import_log_list', {...data })
    // 导入日志表--里面的日期list
const query_import_log_date_list = (data) => axios.post('/query_import_log_date_list', {...data })
    // 手动跑批
const manual_batch = (data) => axios.post('/manual_batch', {...data })
    // 查询当前人的部门
const query_staff_dept_list = (data) => axios.post('/query_staff_dept_list', {...data })
    // 模块开启状态
const query_module_status = (data) => axios.post('/query_module_status', {...data })
    // 自动跑皮
const auto_batch = (data) => axios.post('/auto_batch', {...data })
    // 查询眼睛表单list
const query_eye_form_list = (data) => axios.post('/query_eye_form_list', {...data })
    // 眼睛--单据列表
const query_form_list_file_nos = (data) => axios.post('/query_form_list_file_nos', {...data })
    //撤销
const undo_flow_action = (data) => axios.post('/undo_flow_action', {...data })
    //我发起的
const query_flow_list_start_by_me = (data) => axios.post('/query_flow_list_start_by_me', {...data })
    //修改
const update_flow_info = (data) => axios.post('/update_flow_info', {...data })

// 拜访记录单
const insert_s_visit_record = (data) => axios.post('/insert_s_visit_record', {...data })
    // 漏斗单
const insert_funn = (data) => axios.post('/insert_funn', {...data })
    // 派工单
const insert_s_dispatch = (data) => axios.post('/insert_s_dispatch', {...data })
    // 合同单
const insert_contract = (data) => axios.post('/insert_contract', {...data })
    // 发票单
const insert_invoice = (data) => axios.post('/insert_invoice', {...data })
    // 回款单
const insert_received_cash = (data) => axios.post('/insert_received_cash', {...data })
    // 出差
const insert_s_biz_trip = (data) => axios.post('/insert_s_biz_trip', {...data })
    // 交付单
const insert_s_deliver = (data) => axios.post('/insert_s_deliver', {...data })
    // 外出单
const insert_s_go_out = (data) => axios.post('/insert_s_go_out', {...data })
    // 客户录入单
const insert_customer_form = (data) => axios.post('/insert_customer_form', {...data })
    // 访客计划单
const insert_s_visit_plan = (data) => axios.post('/insert_s_visit_plan', {...data })
    // 自定义表单  点击提交
const insert_cust_form_data = (data) => axios.post('/insert_cust_form_data', {...data })
    //付款单 insert_payment
const insert_payment = (data) => axios.post('/insert_payment', {...data })
    //供应商录用单 insert_supply
const insert_supply = (data) => axios.post('/insert_supply', {...data })
    //入库单  insert_in_store
const insert_in_store = (data) => axios.post('/insert_in_store', {...data })
    //c出库单 insert_out_store
const insert_out_store = (data) => axios.post('/insert_out_store', {...data })
    //采购单 insert_purchase
const insert_purchase = (data) => axios.post('/insert_purchase', {...data })
    //采购发票单 insert_pur_inv
const insert_pur_inv = (data) => axios.post('/insert_pur_inv', {...data })
    /**
     * 重置企业密码
     */
const first_login_alter_ent_acct_pwd = (data) => axios.post('/first_login_alter_ent_acct_pwd', {...data })
    /**
     * 菜品树
     */
const query_category_tree = (data) => axios.post('/query_category_tree', {...data })


const delete_ent_img = (data) => axios.post('/delete_ent_img', {...data })

// 设置桌台区域
const set_table_area = (data) => axios.post('/set_table_area', {...data })
// 查询区域桌台设置列表
const query_table_for_area_list = (data) => axios.post('/query_table_for_area_list', {...data })




/**
 * 2021-09-07 earl 新增
 * @param {*} data 
 * @returns 
 */
const insert_upload_img = ( data ) => axios.post('/insert_upload_img', {...data })
const if_text_valid = ( data ) => axios.post('/if_text_valid', {...data })
const file_upload_ming = (data) => axios.post('/file_upload_ming', data)
// , {headers: {'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq'}}



export {
    insert_cust_form_data,
    delete_ent_img,
    insert_payment,
    insert_out_store,
    insert_pur_inv,
    insert_purchase,
    insert_in_store,
    insert_supply,
    insert_s_biz_trip,
    insert_s_visit_plan,
    insert_customer_form,
    insert_s_go_out,
    insert_received_cash,
    insert_s_deliver,
    insert_funn,
    insert_invoice,
    insert_contract,
    insert_s_dispatch,
    insert_s_visit_record,
    query_flow_list_start_by_me,
    update_flow_info,
    undo_flow_action,
    auto_batch,
    query_form_list_file_nos,
    query_eye_form_list,
    query_module_status,
    query_staff_dept_list,
    manual_batch,
    query_import_log_date_list,
    query_import_log_list,
    insert_role_group_info,
    query_role_group_info_list,
    insert_role_info,
    update_role_group_info,
    update_role_info,
    delete_role_group_info,
    delete_role_info,
    query_role_info_list,
    query_ent_primary_manager_detail,
    delete_ent_sub_manager,
    update_ent_sub_manager,
    set_ent_sub_manager,
    query_dept_staff_list,
    query_ent_dept_list,
    query_ent_sub_manager,
    query_ent_sub_manager_list_by_module,
    query_ent_sub_manager_list,
    update_ent_aut_info,
    query_user_aut_ent,
    add_ent_aut_info,
    aut_ent_have_exist,
    query_user_aut_ent_list,
    toggle_user_ent,
    dismiss_aut_ent,
    delete_un_aut_ent,
    send_check_user_email,
    staff_left_ent,
    get_rel_list,
    query_user_ent_list,
    alter_user_pwd,
    change_user_mobile,
    logout,
    sms,
    register_judge,
    query_cust_list_simple,
    query_user_info,
    query_flow_group_info_list,
    query_post_info_list,
    check_gzh_focus,
    query_all_module_sale_userid,
    query_all_module_sale,
    set_all_module_sale,
    update_form_replace_write,
    query_form_replace_write_new,
    update_staff_node,
    query_staff_node,
    insert_m_login_log,
    delete_notify,
    update_notify,
    query_notify_list,
    insert_notify,
    query_notify_num,
    query_all_prod_list_of_category_sale_rule,
    query_all_prod_list_of_category,
    query_all_module_read_power_list,
    query_all_module_read_power_detail,
    set_all_module_read_power,
    query_aim_module_power_list,
    query_manager_of_form,
    query_customer_file_list,
    query_staff_list_of_dept,
    query_dept_list_of_staff,
    query_all_staff_list_of_dept,
    update_prod_cat_cat_batch,
    update_prod_cat_batch,
    update_prod_sort,
    update_category_sort,
    update_dept_sort,
    query_staff_list_register_not,
    query_staff_list_register_yes,
    query_ent_staffNum_registerNum,
    query_aim_module_power_detail,
    set_aim_module_power,
    query_module_power_detail,
    set_module_power,
    query_user_list_of_module,
    query_user_list_of_role,
    query_user_list_of_roles,
    set_user_module_rel,
    delete_staff_role_rel,
    set_staff_role_rel,
    init_work_day_list,
    get_system_logs_list,
    get_dept_list,
    get_prod_list_like,
    get_prod_list,
    get_staff_list_like,
    get_staff_list,
    get_date_type_list,
    get_cs_info,
    get_my_info,
    update_user_info,
    add_my_advise,
    query_flow_info_list,
    query_flow_form_info,
    query_flow_list_have_appr_by_me,
    query_flow_info_all,
    first_login_alter_ent_acct_pwd,
    query_category_tree,
    set_table_area,
    query_table_for_area_list,
    insert_upload_img,
    if_text_valid,
    file_upload_ming
}